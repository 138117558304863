export const environment = {
  production: false,
  projectName: "SemiDiSenapeDemo",
  dev: {
    REST_API_SERVER: "https://semidisenape.demo.api.testctsolution.it/api"
  },
  variables: {
    defaults: {}
  },
  customTranslationsDirectory: '/assets/custom-translations',
  defaultLang: 'it'
};
